import "./cv.css";
import React from "react";
const Cv = ({ cv }) => {
  return (
    <div className="cv-pdf">
      <div className="text-style title">Lebenslauf des Kandidaten</div>
      <iframe src={cv} type="application/pdf" title="PDF Viewer" width="80%" height="600px" style={{ marginLeft: "10%" }} />
    </div>
  );
};

export default Cv;
