import React, { useEffect, useState } from "react";
import UserAvatar from "../component/userAvatar/userAvatar";
import Documents from "../component/documents/documents";
import Cv from "../component/cv/cv";
import CandidateVideo from "../component/candidateVideo/CandidateVideo";
import "../page.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../component/loading/loading";

const CandidatePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  let { id } = useParams();
  // const url = "/external/candidate/link/0ecceeaf-d32d-457e-8283-bb87b42301c5";
  const url = `/external/candidate/link/${id}`;

  // Documents
  const [documentsState, setdocumentsState] = useState(false);
  let see_hide_documents = "Siehe Dokumente";
  if (!documentsState) {
    see_hide_documents = "Siehe Dokumente";
  } else {
    see_hide_documents = "Dokumente ausblenden";
  }

  // CV
  const [cvState, setCvState] = useState(false);
  let see_hide_cv = "siehe Lebenslauf";
  if (!cvState) {
    see_hide_cv = "siehe Lebenslauf";
  } else {
    see_hide_cv = "siehe Lebenslauf";
  }

  useEffect(() => {
    fetchData()

  }, []);
  const fetchData = async () => {

    try {
      let response = await axios.get(process.env.REACT_APP_URL_API + url,
        {
          headers: {
            "Content-Type": "application/json"
          }
        });

      setIsLoading(false);
      if (response.status == 200) {
        setData(response.data.results.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
    }


  };

  if (isLoading) {
    return (<Loading />);
  }

  if (isError) {
    return (<h1>Der Link ist abgelaufen. Bitte wenden Sie sich an das Jobzioo-Team</h1>);
  }

  if (!isLoading && !isError) {
    return (
      <>
        <UserAvatar
          photo={data.candidate.photo}
          fullName={`${data.candidate.firstname} ${data.candidate.lastname}`}
        />
        <CandidateVideo candidateVideo={data.video} />
        <div className="show-btns">
          <button
            className="show-btn show-cv-btn"
            onClick={() => setCvState(!cvState)}>
            {see_hide_cv}
          </button>
          <button
            className="show-btn show-documents-btn"
            onClick={() => setdocumentsState(!documentsState)}>
            {see_hide_documents}
          </button>
        </div>
        {cvState && <Cv cv={data.cv} />}
        {documentsState && <Documents documents={data.documents} />}
      </>
    );
  }
}





/*const GetData = async (url) => {
  let test = [];
  await axios({
    method: "get",
    url: url,
    proxy: {
      host: "https://apijobzioo.smarteo.tn",
    },
  }).then((res) => {
    // setData(res.data.results.data.data);
    // setIsLoading(false);
    test = res.data.results.data.data;
  });
  return test;
  }*/

export default CandidatePage;
