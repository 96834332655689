import React from "react";
import logoImg from "../../assets/logo.png"
import "./loading.css"
const Loading = () => {
    return (
        <div className="loading-container">
            <div className="typewriter">

                <div className="slide"><i></i></div>
                <div className="paper"></div>
                <div className="keyboard"></div>
            </div>
            <img src={logoImg} alt="" width="225px" />
        </div>
    )
}
export default Loading;