import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import CandidatePage from "./pages/candidatePage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<h1 >Jobzioo</h1>} />
          <Route path="/:id" element={<CandidatePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
