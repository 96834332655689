import React from "react";
import "./documents.css";
const Documents = ({ documents }) => {
  return (
    <>
      <div className="text-style title">Kandidatenunterlagen</div>
      {documents.map((doc, index) => (
        <div className="documents-pdf" key={index}>
          <iframe src={doc} type="application/pdf" title="PDF Viewer" width="80%" height="600px" style={{ marginLeft: "10%" }} />
        </div>
      ))}
    </>
  );
};

export default Documents;
