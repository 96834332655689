import React from "react";
import ReactPlayer from "react-player";
import "./CandidateVideo.css";
const CandidateVideo = ({ candidateVideo }) => {
  return (
    <div className="candidate-video">
      {candidateVideo && (
        <div>
          <div className="text-style title">Kandidatenvideo</div>
          <ReactPlayer url={candidateVideo} controls={true} />
        </div>
      )}
    </div>
  );
};
export default CandidateVideo;
