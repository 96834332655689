import React from "react";
import "./userAvatar.css";
import Logo_footer from "../../assets/Logo-footer.png";
const UserAvatar = ({ photo, fullName }) => {
  return (
    <>
      <div className="user-avatar">
        <div className="user-avatar-name-img">
          <img src={photo} className="user-avatar-img" alt=""/>
          <div className="user-avatar-name">{fullName}</div>
        </div>
        <img className="user-avatar-logo" src={Logo_footer} alt="logo-footer"></img>
      </div>
    </>
  );
};

export default UserAvatar;
